import LeafletMap from "./LeafletMap/LeafletMap";
import React from "react";

export default function Home() {
  return (
    <>
      <main>
        <LeafletMap />
      </main>
    </>
  );
}
